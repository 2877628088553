import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";

import theme from "@landingpage/app/src/theme";
import { Helmet } from "react-helmet";
import { StyledComponent, GlobalStyle } from "./Layout.style";

const Layout = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        />
      </Helmet>
        <GlobalStyle />
        <StyledComponent>{children}</StyledComponent>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
