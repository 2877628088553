import { createTheme } from "@material-ui/core/styles";
import breakpoints from "./breakpoints";
import palette from "./palette";
import typography, { setTypography } from "./typography";

const theme = createTheme({
  breakpoints,
  palette,
  typography,
});

export default setTypography(theme);
