import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.palette.background.default};
    margin: 0;
    padding: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
`;

const StyledComponent = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .center {
    text-align: center;
  }

  .text {
    padding: ${({ theme }) => `${theme.spacing(16)}px ${theme.spacing(6)}px`};
  }

  .MuiButton-root {
    padding: ${({ theme }) => `${theme.spacing(1.5)}px ${theme.spacing(2)}px`};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .text {
      padding: ${({ theme }) =>
        `${theme.spacing(2)}px ${theme.spacing(2)}px
       ${theme.spacing(0)}px ${theme.spacing(2)}px`};
    }

    .text > .MuiTypography-root,
    .text > .MuiCollapse-container {
      margin: ${({ theme }) => `0px ${theme.spacing(3)}px`};
    }

    .mobile-left {
      text-align: left;
    }
    .mobile-right {
      text-align: right;
    }
    .mobile-center {
      text-align: center;
    }
  }
`;

export { GlobalStyle, StyledComponent };
