export default {
  type: "dark",
  background: {
    default: "#ffffff",
    paper: "#ffffff",
    faq: "#ffffff",
    topbar: "#ffffff",
    price: "#ffffff",
    footer: "#ffffff",
    slider: "#ffffff",
    header: "#28292E",
  },
  text: {
    primary: "#6E6E6E",
    secondary: "#000000",
    disabled: "rgba(0, 0, 0, 0.38)",
    header: "#ffffff",
    uspHeader: "#ff0000",
    navBar: "#033360",
  },
  primary: {
    main: "#ff0000",
    dark: "#c90824",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#ff0000",
    dark: "#1bbb69",
    contrastText: "#033360",
  },
  color: {
    green: "#00b432",
  },
  faq: {
    expansionPanel: "#ebeff3",
    text: "#000000",
  },
  footer: {
    link: "rgb(255, 0, 0)",
    p: "rgb(255, 0, 0)",
    border: "#ff0000",
  },
  cookie: {
    background: "#EFEFEFEF",
    title: "#00000",
    text: "#000000",
    acceptButton: "#ff0000",
    acceptText: "#FFFFFF",
    declineButton: "#000000",
  },
  pricing: {
    background: "#28292E",
    uspText: "#000000",
    ribbonColor: "#00b432",
    ribbonShadow: "#009b19",
    inactiveRibbonColor: "rgb(186, 232, 199)",
    selectPlanBackground: "#FFFFFF",
    yearDefault: "#617991",
  },
  slider: {
    arrow: "#ff0000",
    text: {
      primary: "#000000",
    },
  },
};
