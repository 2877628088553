/* eslint-disable no-param-reassign */
import PixelToRem from "@landingpage/core/src/utils/PixelToRem";

const fontFamily = '"Source Sans Pro", sans-serif';
const fontFamilyAlt = '"Source Sans Pro", sans-serif';
const fontSize = 14;
const htmlFontSize = 16;
const fontWeightLight = 300;
const fontWeightRegular = 300;
const fontWeightMedium = 600;

const typography = {
  pxToRem: PixelToRem,

  fontFamily,
  fontFamilyAlt,
  fontSize,
  htmlFontSize,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  useNextVariants: true,
  suppressDeprecationWarnings: true,
};

const setTypography = (theme) => {
  theme.typography.button = {
    ...theme.typography.button,
    fontSize: PixelToRem(18),
    fontWeight: 400,
    textTransform: "none",
    fontFamily: fontFamilyAlt,

    [theme.breakpoints.up("lg")]: {
      fontSize: PixelToRem(18),
    },
  };

  theme.typography.body1 = {
    ...theme.typography.body1,
    fontSize: PixelToRem(21),
    fontWeight: fontWeightRegular,
    color: theme.palette.text.primary,

    [theme.breakpoints.up("lg")]: {
      fontSize: PixelToRem(23),
    },
  };
  theme.typography.body2 = {
    ...theme.typography.body2,
    fontSize: PixelToRem(21),
    fontWeight: fontWeightRegular,
    color: theme.palette.text.primary,

    [theme.breakpoints.up("lg")]: {
      fontSize: PixelToRem(23),
    },
  };
  theme.typography.subtitle1 = {
    ...theme.typography.subtitle1,
    fontSize: PixelToRem(18),
    fontWeight: fontWeightRegular,
  };
  theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    fontSize: PixelToRem(18),
    fontWeight: fontWeightRegular,
  };

  theme.typography.h1 = {
    ...theme.typography.h1,
    fontSize: PixelToRem(38),
    fontWeight: fontWeightMedium,
    color: theme.palette.text.primary,
    marginBottom: PixelToRem(24),

    [theme.breakpoints.up("md")]: {
      fontSize: PixelToRem(48),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: PixelToRem(54),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: PixelToRem(58),
    },
  };
  theme.typography.h2 = {
    ...theme.typography.h2,
    ...theme.typography.h1,
  };
  theme.typography.h3 = {
    ...theme.typography.h3,
    fontSize: PixelToRem(35),
    fontWeight: fontWeightMedium,
  };
  theme.typography.h4 = {
    ...theme.typography.h4,
    fontSize: PixelToRem(28),
    fontWeight: fontWeightRegular,
    color: theme.palette.text.primary,
  };
  theme.typography.h5 = {
    ...theme.typography.h5,
    fontSize: PixelToRem(26),
    fontWeight: fontWeightMedium,
    color: theme.palette.text.primary,
  };
  theme.typography.h6 = {
    ...theme.typography.h6,
    fontSize: PixelToRem(18),
    fontWeight: fontWeightMedium,
    color: theme.palette.text.secondary,
  };

  return theme;
};

export default typography;

export { setTypography };
